html{
  padding: 0 !important;
}

body{
  font-family: 'Poppins';
  background-color: #F0F0F0;
}

.navbar{
  background-color: #1d1d1d !important;
  padding-top:0px;
  z-index: 1030;
  padding-bottom:5px;
}

.menu-nav-pills .nav-item .nav-link{
  background-color: transparent !important;
  border:none !important;
  cursor: pointer;
}

.text-default{
  color:#AA272F;
  text-decoration: none;
}

.navbar-nav .nav-link{
  color:#c4c4c4 !important;
  font-weight: bold;
  border:none;
  margin-right: 20px;
  border-bottom: 3px solid transparent;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:active,
.navbar-nav .nav-link.active{
  color: #AA272F !important;
  font-weight: bold;
  border:none;
  background-color: transparent;
  border-bottom: 3px solid #AA272F;
}

.nav-pills-menu .nav-item .nav-link{
  background-color: transparent;
  border:none;
}

.big-menu{
  box-shadow: 1px 2px 3px #161616;
}

.big-menu .tab-content{
  margin-top:0px !important;
}

.big-menu .sub_submenu .nav-link{
  font-weight:lighter;
  color:#c4c4c4;
}

.big-menu .sub_submenu .nav-link.disabled{
  color:#696969;
}

.big-menu .nav-item .nav-link.disabled{
  color:#696969 !important;
}

.big-menu .sub_submenu .nav-link:hover{
  color:#AA272F;
}

.szh-menu__submenu{
  margin:10px;
  margin-left:0px;
}

.szh-menu__item{
  padding:10px 30px !important;
  color:#221F1F !important;
  font-weight: bold;
  margin:10px;
}

.szh-menu__item:hover,
.szh-menu__item--hover,
.szh-menu__item--active{
  color: #AA272F !important;
  font-weight: bold;
  background-color: #fff !important;
  background: rgb(0,0,0);
  background: linear-gradient(270deg, rgba(0,0,0,0.48503151260504207) 0%, rgba(194,194,194,0.8715861344537815) 24%, rgba(255,255,255,0.7399334733893557) 51%, rgba(255,255,255,1) 100%);
}

.szh-menu__item:hover a{
  color: #AA272F !important;
}

.szh-menu__item a{
  font-weight: bold;
}

.szh-menu__item a:hover{
  color: #AA272F !important;
  background-color: transparent;
}

.szh-menu__item .dropdown-item{
  margin:0px !important;
  padding:0px !important;
}

.topBgFloat{
  background-color: #a10f2b;
  position: absolute;
  height: 150px;
  width: 100%;
  top:0;
  z-index: -1;
}

.circle-btn{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
  border:1px solid #C7B37F;
}

.link-default{
  cursor: pointer;
}

.link-default svg{
  float:right;
  margin-top:5px;
}

.link-default:hover,
.link-default:focus,
.link-default.active{
  color: #AA272F !important;
}

.box{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  background-color: #fff;
  color:#333;
  text-decoration: none;
}

.shadow-low{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* Timeline Container */
.timeline {
  background: #fff;
  margin: 20px auto;
  padding: 20px;
}

/* Outer Layer with the timeline border */
.outer {
  border-left: 2px solid #a10f2b;
}

/* Card container */
.card {
  position: relative;
  margin: 0 0 20px 20px;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
}

/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
}

/* Title of the card */
.title {
  position: relative;
}

/* Timeline dot  */
.title::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 999px;
  left: -37px;
  border: 3px solid #a10f2b;
  margin-top: 5px;
}
.image-box .image-box-image{
  position: relative;
  height:200px;
  overflow: hidden;
}
.image-box .image-box-image img{
  position: absolute;
  left: 50%;
  top: 50%;
  object-fit: cover;
  width: 100%;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

.link-default{
  text-decoration: none;
}

.avatar-container{
  display: inline-flex;
  border:1px solid rgb(223, 223, 223);
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.avatar-container .avatar-frame{
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
}

.avatar-container .avatar-frame img{
  width: 50px;
}

.avatar-container .avatar-title{
  font-size:12pt;
  margin-left:10px;
  margin-top:10px;
}

.dropzone{
  background-color: rgb(249 249 249);
  padding: 20px;
  border: 2px dotted #e6e6e6;
  color: #bdbdbd;
}

.logged-in-navbar{
  list-style: none;
  padding: 0px;
  display: flex;
  float: right;
  padding-top:2px;
}

.logged-in-navbar li a{
  color: rgb(204, 204, 204);
  text-decoration: none;
  font-size: 10pt;
  margin-left: 10px;
}

.avatar-m{
  width: 70px;
  height: 70px;
  border-radius: 50% !important;
}

.membership-badge{
  height:30px !important;
  padding:5px 10px !important;
  border-radius: 10px;
}

.membership-public{
  color:#848484;
  background-color: #C4C4C4;
}

.membership-cab{
  color:#fff;
  background-color: #C7B37F;
}

.membership-pro{
  color:#fff;
  background-color: #AA272F;
}

.break-text{
  word-break: break-all;
}

.action-link{
  color: #333;
  font-size: 10pt;
  text-decoration: none;
  margin-left:5px;
}

.action-link svg{
  margin-right:3px;
  font-size:9pt;
}

.menu-panel{
  list-style: none;
  padding: 0px;
}

.menu-panel li a{
  display: block;
  padding:10px 20px;
  text-decoration: none;
  color: #C4C4C4;
  border-color: #fff;
  border-left:3px solid transparent;
}

.menu-panel li a > svg{
  margin-right:10px;
}

.menu-panel li a:hover{
  border-left-color: #a10f2b;
  color: #1c1c1c;
  font-weight: bold;
}

.menu-panel li a:hover path,
.menu-panel li a:hover polygon,
.menu-panel li a:hover rect,
.menu-panel li a.active path,
.menu-panel li a.active polygon,
.menu-panel li a.active rect{
  stroke: #a10f2b;
}

.menu-panel li a.active{
  border-left-color: #a10f2b;
  color: #1c1c1c;
  font-weight: bold;
}

.toggle-height-body{
  overflow: hidden;

  -webkit-mask-image: -webkit-gradient(linear, left top, 
    left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

.portfolio_detail_canvas{
  width:50%;
}

.tab-content{
  border-top:0px;
  margin-top:-16px;
}

.nav-pills .nav-item .nav-link{
  margin-right:10px;
  margin-bottom:10px;
  border-radius: 15px;
  background-color: #E5E5E5;
  border:1px solid #E5E5E5;
  color:#000;
}

.nav-pills .nav-item .nav-link.active{
  background-color: #465A82;
  color: #fff;
  border:1px solid #465A82;
}

.nav-tabs{
  border-bottom: 0px !important;
}

.overlay-gradient{
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(194, 194, 194, 0) 0%, rgba(37, 37, 37, 0.486) 24%, rgba(49, 49, 49, 0.781) 51%, rgb(0, 0, 0) 100%);
}

.per_notif{
  border-top: 1px solid #e6e6e6;
  padding: 10px;
  cursor: pointer;
}

.per_notif:hover, .notif_read{
  background-color: #dddddd;
}

.breadcrumb .breadcrumb-item a{
  color:#C7B37F;
}

.dropdown-menu{
  padding:0;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-item{
  padding:10px 20px;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  margin-top: -1px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>.dropdown-menu>.dropdown-item {
  padding: 10px 20px;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
}

.hard-shadow .modal-content{
  box-shadow: rgba(0, 0, 0, 0.5) 20px 100px 68px;
}

.footer{
  background-color: rgb(137, 14, 37);
  padding-top: 50px;
  padding-bottom: 70px;
  color:rgb(218, 218, 218);
}

.footer-link{
  margin-top:10px;
  list-style-type: none;
  padding: 0;
  line-height: 35px;
}

.footer-link > li > a{
  color:rgb(199, 199, 199);
  text-decoration: none;
}

.footer-link > li > a:hover{
  text-decoration: underline;
}

.public-DraftStyleDefault-block{
  margin:2px !important;
}

.porto-body p{
  margin-bottom: 5px;
}

.m-link-m{
  padding-left:20px;
  word-break: break-all !important;
  white-space:break-spaces !important;
}

.m-nav .navbar-brand{
  color:#fff !important;
}

.m-nav .navbar-toggler{
  background-color: #dfdfdf;
  border:none;
}

.m-nav-right{
  display: inline-block;
}

.m-nav-right a{
  display: inline-block;
  margin-left: 30px;
  color:rgba(255,255,255,.55) !important;
}

.m-nav-right a:hover{
  color:rgba(255,255,255,1) !important;
}

.slick-next{
  right: 10px !important;
}

.slick-prev{
  left: 10px !important;
  z-index: 1;
}

.login-container{
  display: flex;
}

.login-image{
  height: 100vh;
  background-color: #a10f2b;
}

.login-image .icon{
  width:150px;
}

.login-content{
  background-color: #fff;
  height: 100vh;
  width: 100%;
  overflow: scroll;
}

.btn-google{
  background: #4285F4;
  color: rgb(233, 233, 233);
  border-radius: 10px !important;
  border:none;
  padding:10px 20px;
}

.btn-google:hover{
  color: #fff;
}

.btn-google > img{
  width:30px;
  margin-right:10px;
}

.btn{
  border-radius: 10px !important;
}

.def-link{
  color:#C7B37F;
  text-decoration: none;
  cursor: pointer;
}

.def-link:hover{
  color:#C7B37F;
  font-weight: bold;
}

.lined {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #C4C4C4; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
} 

.glass{
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.19);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.lined span { 
   background:#fff; 
   padding:0 10px; 
   color: #C4C4C4;
}

.btn-default{
  background-color: #a10f2b;
  color: #fff;
}

.btn-default:hover{
  background-color: #CA6C71;
  color: #fff;
}

.btn-default:disabled{
  background-color: #C4C4C4;
  cursor: not-allowed;
  pointer-events: all !important;
}

.btn-default-outline{
  color: #a10f2b;
  border: 2px solid #a10f2b;
  font-weight: bold;
  background: transparent;
}

.btn-default-outline:hover{
  background: #a10f2b;
  color: #fff;
}

.form-control{
  border-radius: 0px !important;
  background-color: #F3F3F4;
  border: none;
}

.form-control:active, .form-control:focus{
  outline:1px solid #AA272F;
  box-shadow: none;
}

.dropdown-item.active, .dropdown-item:active{
  background-color: #AA272F;
}

.image-overlay::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .5;
}

.accordion-button:not(.collapsed){
  color:#AA272F;
  background-color: #eee0e0;
}
.accordion-button:not(.collapsed)::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23AA272F%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.mobile-bottom-nav{
  position: fixed;
  bottom: 0;
  background-color: #F0F0F0;
  box-shadow: 2px -2px 15px  #f5f5f5;
  width: 100%;
  padding:10px;
  border-top:1px solid #e6e6e6;
}

.mobile-bottom-nav .nav-link{
  color: #AA272F;
  opacity: 0.6;
}

.mobile-bottom-nav .nav-link:hover{
  opacity: 1;
}

.default-nav-mt{
  margin-top: 10px;
  margin-bottom: 120px;
}

.btn-scan-mobile{
  position: absolute;
  display: inline;
  padding:10px 15px !important;
  border-radius: 50px !important;
  margin-top:-30px;
  margin-left:-25px;
}

.highlight-area{
  background-color: #AA272F;
  color:#fff;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.highlight-area > div > p{
  margin-bottom: 0px;
}

.media_partner .slick-track {
  display: flex;
}
.media_partner .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.media_partner .slick-track .slick-slide img{
  max-height:150px;
}

.wa-float{
  margin-bottom:10px;
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .login-image{
    width:800px !important;
  }

  .banner-header{
    padding-top:60px;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .banner-header{
    padding-top:0px;
  }

  .wa-auth{
    margin-bottom: 70px !important;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .portfolio_detail_canvas{
    width:100%;
  }

  .banner-header{
    padding-top:0px;
  }

  .wa-auth{
    margin-bottom: 70px !important;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .portfolio_detail_canvas{
    width:100%;
  }

  .banner-header{
    padding-top:0px;
  }

  .accordion-body img{
    width: 100% !important;
    height: auto !important;
  }

  .wa-auth{
    margin-bottom: 70px !important;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .portfolio_detail_canvas{
    width:100%;
  }

  .banner-header{
    padding-top:0px;
  }

  .accordion-body img{
    width: 100% !important;
    height: auto !important;
  }

  .wa-auth{
    margin-bottom: 70px !important;
  }
}

.stepper button.completed,
.stepper button.active{
  background-color: #AA272F;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@900&display=swap');